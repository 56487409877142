import React, { useEffect, useState } from 'react';
import { Navbar, Nav, NavDropdown, Container, Image } from 'react-bootstrap';
import { User } from '../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { Skeleton } from 'antd';

export const CustomNavbar: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(`/api/users/me`, {
      credentials: 'include' // Include credentials in the request
    })
      .then(resp => resp.json())
      .then(data => {
        const user: User = data;
        setUser(user);
        setIsLoading(false);
      });
  }, []);

  return (
    <Navbar variant="dark" expand="lg" className="navbar-custom sticky-top">
      <Container>
        <Navbar.Brand onClick={() => (window.location.href = '/Homepage')}>
          BigBrother
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link onClick={() => (window.location.href = '/user/search')}>
              User
            </Nav.Link>
            <Nav.Link onClick={() => (window.location.href = '/server/search')}>
              Server
            </Nav.Link>
          </Nav>
          <Nav>
            <NavDropdown menuVariant="dark" title={<UserToggle user={user} isLoading={isLoading} />} id="basic-nav-dropdown">
              <NavDropdown.Item onClick={() => (window.location.href = `/user/${user?.id}`)}>
                Accountdetails
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => (window.location.href = '/friends')}>
                Friends
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => (window.location.href = '/profile')}>
                Profile
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => (window.location.href = '/logout')}>
                <span style={{ color: "#ff0000" }}>
                  Logout{" "}
                  <FontAwesomeIcon icon={faRightFromBracket} style={{ color: "#ff0000" }} />
                </span>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

interface UserToggleProps {
  user: User | null;
  isLoading: boolean;
}

const UserToggle: React.FC<UserToggleProps> = ({ user, isLoading }) => {
  if (isLoading) {
    return (
      <React.Fragment>
        <Skeleton.Avatar active size={30} shape="circle" style={{ backgroundColor: '#f8f8f8' }} />
        <Skeleton.Input active style={{ marginLeft: '0.5rem', marginTop:"5px", maxWidth: "20px", maxHeight: "20px", backgroundColor: '#f8f8f8' }} />
      </React.Fragment>
    );
  }

  return (
<React.Fragment>
  {user && (
    <Image
      src={user.avatar_url}
      alt="User"
      roundedCircle
      width={30}
      height={30}
    />
  )}
  {user && (
    <span
    style={{
      marginLeft: '0.5rem',
      maxWidth: '150px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      textAlign: 'center',
    }}
  >
    {user.username.length > 15 ? `${user.username.slice(0, 15)}...` : user.username}
  </span>
  )}
</React.Fragment>
  );
};
