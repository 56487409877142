import React, { useEffect, useState, Suspense } from "react";
import { checkIfUserIsAuthenticated } from "./components/Authentication";
import { BrowserRouter, Routes, Route, Navigate, useLocation } from "react-router-dom";
import { CustomNavbar } from "./components/Navbar";
import { RedirectLogin } from "./pages/Login";

const CallbackHandler = React.lazy(() => import("./pages/CallbackHandler"));
const Homepage = React.lazy(() => import("./pages/Homepage"));
const Login = React.lazy(() => import("./pages/Login"));
const Logout = React.lazy(() => import("./pages/Logout"));
const UserSearch = React.lazy(() => import("./pages/UserSearch"));
const UserStatistics = React.lazy(() => import("./pages/UserStatistics"));
const ServerSearch = React.lazy(() => import("./pages/ServerSearch"));
const ServerStatistics = React.lazy(() => import("./pages/ServerStatistics"));
const Friends = React.lazy(() => import("./pages/Friends"));
const Profile = React.lazy(() => import("./pages/Profile"));
const NotFound = React.lazy(() => import("./pages/NotFound"));
const UserServerStatistics = React.lazy(() => import("./pages/UserServerStatistics"));
const UserGameStatistics = React.lazy(() => import("./pages/UserGameStatistics"));

export const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<div></div>}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/Homepage" element={<ProtectedRoute><Homepage /></ProtectedRoute>} />
          <Route path="/redirectLogin" element={<RedirectLogin />} />
          <Route path="/callback" element={<CallbackHandler />} />
          <Route path="/Logout" element={<Logout />} />
          <Route path="/user/search" element={<ProtectedRoute><UserSearch /></ProtectedRoute>} />
          <Route path="/user/:userid" element={<ProtectedRoute><UserStatistics /></ProtectedRoute>} />
          <Route path="/server/search" element={<ProtectedRoute><ServerSearch /></ProtectedRoute>} />
          <Route path="/server/:serverid" element={<ProtectedRoute><ServerStatistics /></ProtectedRoute>} />
          <Route path="/friends" element={<ProtectedRoute><Friends /></ProtectedRoute>} />
          <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
          <Route path="/user/:userid/server/:serverid" element={<ProtectedRoute><UserServerStatistics /></ProtectedRoute>} />
          <Route path="/user/:userid/game/:gameid" element={<ProtectedRoute><UserGameStatistics /></ProtectedRoute>} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

const ProtectedRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(undefined);
  const location = useLocation();
  
  sessionStorage.setItem('previousLocation', location.pathname);
  useEffect(() => {
    checkIfUserIsAuthenticated().then((result) => {
      setIsAuthenticated(result);
    });
  }, []);

  if (isAuthenticated === undefined) {
    return null; // Render nothing until the promise resolves
  }

  return (
    <>
      {isAuthenticated ? <CustomNavbar /> : null}
      {isAuthenticated ? children : <Navigate to="/" replace={true} />}
    </>
  );
};
