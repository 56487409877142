export async function checkIfUserIsAuthenticated(): Promise<boolean> {
    try {
        const response = await fetch(`/api/check-auth`, {
            credentials: 'include' // Include credentials in the request
        });
        if (response.status === 200) {
            return true;
        } else if (response.status === 401) {
            return false;
        } else {
            console.error('Unexpected response status:', response.status);
            return false;
        }
    } catch (error) {
        console.error('Error occurred while checking authentication:', error);
        return false;
    }
}