import React, {useEffect, useState} from 'react';
import { Navigate } from "react-router-dom";
import { checkIfUserIsAuthenticated } from '../components/Authentication';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { notification } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

const Login: React.FC = () => {
    const [isAuthenticated, setIsAuthenticated] = useState < boolean | undefined > (
        undefined
    );
    const OnError = localStorage.getItem('OnError');
    let onetime = 0;

    useEffect(() => {
      // Fetch user authentication status
      checkIfUserIsAuthenticated().then((result) => {
        setIsAuthenticated(result);
        // Check if authentication failed
        if (OnError === 'Forbidden' && onetime === 0) {
          notification.success({
            message: 'Login Failed',
            placement: 'top',
            description: 'You need to be in a Server with this bot to login.',
            icon: <CloseCircleOutlined style={{ color: '#f5222d' }} rev={undefined} />,
          });
          // eslint-disable-next-line react-hooks/exhaustive-deps
          onetime = 1;
          // Clear the stored error from local storage
          localStorage.removeItem('OnError');
        }
      });
    }, [OnError]);

    if (isAuthenticated === undefined) {
        return null; // Render nothing until the promise resolves
    }
    
    if (isAuthenticated) {
        return (<Navigate to="/Homepage"/>)
    }
    
    return (
        <div className="d-flex align-items-center justify-content-center vh-100">
        <Container className="d-flex flex-column align-items-center">
      <Row className="mt-5">
        <Col>
          <Image src="/api/avatar" roundedCircle fluid width={512} height={512} />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <h3 className="text-center">BigBrother is watching you!</h3>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
        <Button variant="primary" size="lg" onClick={() => (window.location.href = '/redirectLogin')}>Login with Discord</Button>
        </Col>
      </Row>
    </Container></div>
    )
}

export const RedirectLogin: React.FC = () => {
  useEffect(() => {
      // Fetch the OAuth URL from the server
      fetch(`/api/oauth-url`, {
          credentials: 'include', // Include cookies in the request
      })
          .then(response => response.json())
          .then(data => {
            console.log(data)
              // Redirect the user to the retrieved OAuth URL
              window.location.href = data.OAUTH_URL;
          })
          .catch(error => {
              console.error('Error fetching OAuth URL:', error);
          });
  }, []);

  return <div></div>;
}

export default Login